<template>
  <page-header-wrapper>
    <!-- <a-card> -->
    <!-- <a-card v-if="loadData.length !== 0" :bordered="false" :loading="loading"> -->
    <a-row v-if="loadData.length !== 0" :gutter="24">
      <a-col :span="8" class="myCol" v-for="house in loadData" :key="house.id" @click="handleEdit(house)">
        <a-card hoverable class="myCard">
          <img
            slot="cover"
            alt="example"
            class="myImg"
            :src="house.cover ? `${host + '/' + house.cover + THUMBNAIL_ORIGIN }` : errorImg"
          />
          <a-row class="house">
            <a-col :span="12" class="name">{{ house.name }}</a-col>
            <a-col :span="12" class="address">{{ house.area.join('') }}</a-col>
          </a-row>
          <a-row>
            <template v-if="house.label.length !== 0">
              <a-tag v-for="tag in house.label" :color="tag.color" :key="tag.id" class="tag">
                {{ tag.value }}
              </a-tag>
            </template>
            <a-col v-else class="tag_empty">
              <span class="layout">暂未设置标签</span>
            </a-col>
          </a-row>
          <a-row class="house_base" type="flex" justify="space-between">
            <a-col :span="7" class="price">
              <div class="box">
                <div class="title">{{ house.salePrice ? `${priceFormat(house.salePrice)} 元/㎡` : '暂未设置' }}</div>
                <div class="desc">单价</div>
              </div>
            </a-col>
            <a-col :span="7" class="price">
              <div class="box">
                <div class="title">{{ house.roomAreaAllRange }}</div>
                <div class="desc">套内</div>
              </div>
            </a-col>
            <a-col :span="7" class="price">
              <div class="box">
                <div class="title">{{ house.roomAcreageRange }}</div>
                <div class="desc">建面</div>
              </div>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
    <a-card v-else :bordered="false">
      <a-empty />
    </a-card>
    <!-- </a-card> -->
    <!-- </a-card> -->
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { queryHouses } from '@/api/houses'
import errorImg from '@/assets/img/errorImg.png'
const columns = [
  {
    title: '楼盘名称',
    dataIndex: 'name'
  },
  {
    title: '单价',
    dataIndex: 'salePrice',
    scopedSlots: { customRender: 'salePrice' }
  },
  {
    title: '开发商',
    dataIndex: 'developer',
    scopedSlots: { customRender: 'developer' }
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    sorter: true
  },
  {
    title: '所在位置',
    dataIndex: 'area'
  },
  {
    title: '标签',
    dataIndex: 'label',
    scopedSlots: { customRender: 'label' }
  },
  {
    title: '操作',
    key: 'action',
    width: '150px',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis
  },
  data() {
    // this.columns = columns
    return {
      columns,
      errorImg,
      loading: false,
      // create model
      visible: false,
      confirmLoading: false,
      mdl: null,
      // 批量删除 modal
      del: {
        ModalText: '您要删除这些项目吗',
        visible: false,
        confirmLoading: false
      },
      loadData: [],
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  created() {},
  computed: {
    layout() {
      let num = 6
      const len = this.loadData.length
      switch (len) {
        case 0:
          num = 0
          break;
        case 1:
          num = 24
          break;
        case 2:
          num = 12
          break;
        case 3:
          num = 8
          break;
        case 4:
          num = 6
          break;
        default:
          num = 4
          break
      }
      return num
    },
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    },
    host() {
      return this.$store.state.oss.host
    },
    serial() {
      return this.$store.state.env.serial
    },
    userId() {
      return this.$store.state.user.info.id
    }
  },
  mounted() {
    this.reload()
  },
  methods: {
    reload() {
      this.loading = true
      const requestParameters = {
        developerId: this.userId,
        page: 0,
        size: 20
      }
      queryHouses(requestParameters)
        .then(res => {
          const result = this.resFormat(res, 'area')
          this.getLabel(result.data)
          this.loadData = result.data
        }).finally(() => {
          this.loading = false
        })
    },
    editInfo(item, key, newKey) {
      if (item[key]) {
        item[newKey] = item[key].split()
        if (item[key].indexOf(',') !== -1) {
          const newArr = item[key].split(',').sort((a, b) => a - b)
          item[newKey] = [newArr[0], newArr[1]].join('-') + '㎡'
        } else {
          item[newKey] = item[key] + '㎡'
        }
      } else {
        item[newKey] = '暂未设置'
      }
    },
    handleAdd() {
      this.$router.push({
        path: '/residence/create'
      })
    },
    handleEdit(record) {
      console.log(record, 'record')
      this.$router.push({
        name: 'housesEdit',
        path: '/residence/houses',
        query: {
          id: record.id,
          link: this.serial + 'rest/houses/' + record.id
        }
      })
    },
    getLabel(data) {
      data.forEach(item => {
        if (item.label === undefined || item.label === null || item.label === '') {
          item.label = []
        } else {
          if (item.label.indexOf(',') !== -1) {
            const arr = item.label.split(',')
            item.label = arr.map((child, index) => {
              if (index % 2 === 0) {
                return (child = {
                  color: '#108ee9',
                  value: child
                })
              } else {
                return (child = {
                  color: '#f50',
                  value: child
                })
              }
            })
          } else {
            item.label = [{ value: item.label, color: '#108ee9' }]
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .Mylabel {
    /deep/ .ant-tag-has-color {
      margin-bottom: 1px !important;
    }
  }
  .myCol {
    min-width: 530px;
  }
  .myCard {
    width: 100%;
    position: relative;
    margin-bottom: 24px;
    .ant-card-body {
      padding: 10px 24px 24px 24px;
    }
    .house {
      margin-bottom: 8px;
      .name {
        font-size: 24px;
        font-weight: 700;
      }
      .address {
        position: absolute;
        right: 20px;
        bottom: 6px;
        text-align: right;
        font-size: 12px;
      }
    }
    .tag_empty {
      height: 31px;
      color: #888888;
      .layout {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .house_base {
      margin-top: -5px;
    }
    .myImg {
      height: 300px;
    }
    .tag {
      font-size: 14px;
      padding: 2px 8px;
      margin-bottom: 5px;
    }
    .price{
      // width: 30%;
      background: #f2f2f2;
      border: 1px solid rgba(215, 215, 215, 1);
      margin-top: 15px;
      text-align: center;
      height: 80px;
      position: relative;
      // margin-right: 16px;
      .box {
        width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        overflow: hidden;
        .title {
          font-size: 18px;
          font-weight: 700;
        }
        .desc {
          font-size: 14px;
        }
      }
    }
    /deep/ .ant-card-cover {
      padding: 24px 24px 0 24px !important;
    }
  }
</style>
